@use 'variables' as variables;
@use 'colors' as colors;

.loading-banner {
  height: 100vh;
  min-height: 700px;
  min-width: 350px;
  max-height: 1080px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.banner {
  height: 100vh;
  min-height: 666px;
  max-height: 900px;
  min-width: 350px;
  .parallax-banner {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .logo-text {
    height: 100%;
    width: 100%;
    position: relative;

    @media only screen and (max-width: 768px) {
      padding: 0px 8px;
      bottom: -50%;
    }

    section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin: 0px auto;
      width: 100%;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    .total-prizes-container {
      display: flex;
      flex-direction: column;
      position: absolute;
      height: 50%;
      width: 100%;
      bottom: 32%;
      left: 0px;
      right: 0px;
      margin: auto;

      p {
        line-height: 5px;
        text-align: center;
        letter-spacing: 1px;
        margin-bottom: 24px;

        @media screen and (max-width: 768px) {
          line-height: 0px;
          margin-bottom: 12px;
        }
      }
      @media screen and (min-width: 768px){
        height: 1em;
      }
    }
    .total-prize-1 {
      font-size: 36px;
      font-weight: 700;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }

    .total-prize-2 {
      color: #FFD285;
      font-size: 46px;
      line-height: 1em;
      margin-top: 19px;

      @media screen and (min-width: 768px) {
        margin-top: 4.5em;
        padding: 1em;
      }
    }

    .event-time {
      color: #52B8FF;
      font-size: 24px;
      height: 29px;
      font-weight: 700;
      white-space: pre-wrap;

      @media screen and (max-width: 768px) {
        height: unset;
        line-height: 18px !important;
        padding: 0px 8px;
        font-size: 12px;
      }
    }

    .private-table {
      font-size: 28px;
      line-height: 1.4em;
      margin-top: -10px;

      @media screen and (min-width: 1000px) {
        font-size: 46px;
      }
    }
  }


  .poker-heart, .poker-diamond, .poker-clubs, .poker-spade {
    width: 100%;
    height: 100%;
    background-size: 40% 40% !important;
    background-repeat: no-repeat;
  }

  .poker-heart {
    background-position: left -150px top 200px !important;
  }

  .poker-diamond {
    background-position: right -150px top 200px !important;
  }

  .poker-clubs {
    background-position: left -55px bottom 200px !important;
  }

  .poker-spade {
    background-position: right -75px top 350px !important;
  }

  .coin-1, .coin-2, .coin-3, .coin-4, .coin-5, .coin-6 {
    width: 100%;
    height: 100%;
    background-size: 50px 50px !important;
    background-repeat: no-repeat;
  }

  .coin-1 {
    background-position: left 20% top 20% !important;
  }

  .coin-2 {
    background-size: 100px 100px !important;
    background-position: right 20% top 30% !important;
  }

  .coin-3 {
    background-position: top 10% right 20% !important;
  }

  .coin-4 {
    background-size: 100px 100px !important;
    background-position: top 20% left 0% !important;
  }

  .coin-5 {
    background-position: top 20% right !important;
  }

  .coin-6 {
    background-size: 100px 100px !important;
    background-position: top 40% left 15% !important;
  }

  .prizes-container{
    margin-top: 25em;

    @media screen and (min-width: 768px) {
      margin-top: 10em;
    }
  }

  .red-paint, .blue-paint {
    width: 200px;
    height: 200px;

    @media screen and (min-width: 768px) {
      width: 500px;
      height: 500px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .red-paint {
    margin: 124px auto;
    margin-left: 38%;

    @media screen and (min-width: 768px) {
      margin: 200px auto;
      margin-top: 100px;
      margin-left: 40%;
    }
  }

  .blue-paint {
    margin: 140px auto;
    margin-left: 11%;

    @media screen and (min-width: 768px) {
      margin: 250px auto;
      margin-top: 100px;
      margin-left: 20%;
    }

  }

  .leaderboard-title {
    margin: 144px auto;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    .rank-images-container {
      width: 95%;
      margin: auto;

      .lhs-label {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 2px;
        color: colors.$prize-text-gold;
      }

      .rank-1-container, .rank-2-3-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        aside {
          position: relative;
          width: 356px;
          height: 150px;

          @media screen and (min-width: 769px) {
            width: unset;
            height: unset;
          }
        }
      }

      .first-winner-name {
        position: absolute;
        top: 23%;
        left: 35%;
        font-size: 32px;

        @media screen and (max-width: 768px) {
          top: 21%;
          font-size: 18px;
        }
      }

      .prize-1 {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        top: 52%;
        left: 32%;
        font-size: 24px;
        width: 55%;

        @media screen and (max-width: 768px) {
          top: 50%;
        }
        
        .gold {
          color: colors.$prize-text-gold;
          font-size: 24px;

          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
        }

        .prize-amount {
          font-size: 24px;

          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
      }

      .second-winner-name, .third-winner-name {
        position: absolute;
        top: -20px;
        left: 32%;
        font-size: 28px;

        @media screen and (max-width: 768px) {
          top: -18%;
          font-size: 18px;
        }

      }

      .rank-2-3-container {


        aside {
          position: relative;
          width: 250px;
          height: 75px;

          @media screen and (min-width: 769px) {
            width: 460px;
            height: 138px;
          }

          .prize-2-3 {
            position: absolute;
            top: 50%;
            left: 25%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 61%;

            @media screen and (max-width: 768px) {
              top: 46%;
            }
            
            .gold {
              color: colors.$prize-text-gold;
            }
            p {
              font-size: 20px;

              @media screen and (max-width: 768px) {
                font-size: 12px;
              }

            }
          }
        }


        @media screen and (max-width: 767px) {
          flex-direction: column;
          gap: 32px;

          aside {
            margin: auto;
          }

          p {
            font-size: 18px;
          }
        }        
      }
    }

    p {
      text-align: center;
      font-size: 20px;

      @media screen and (min-width: 768px) {
        font-size: 40px;
      }

    }
  }

}

.spinner-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.spinner {
  background-image: url('../assets/micardTournament/btn_entry_hans.webp');
  width: 100%;
  height: calc(100vw * 919/1440);
  max-height: 919px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background-size: contain !important;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: rotate-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: rotate-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  

  @media screen and (max-width: 768px) {
    margin-top: 47%;
    background-position: unset;
    left: 0px !important;
    right: 0px !important;
  }
}

.coins-left {
  width: 100%;
  height: 100%;
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: left 0px top 0px !important;
  inset: 0px !important;

  @media screen and (max-width: 768px) {
    background-size: 68% !important;
    background-position: left -27% top 0% !important;
  }
}

.coins-right {
  width: 100%;
  height: 100%;
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: right 0px top 0px !important;
  inset: 0px !important;

  @media screen and (max-width: 768px) {
    background-size: 58% !important;
    background-position: right -11% top 0% !important;
  }
}

.girl {
  width: 95%;
  height: 100%;
  margin: auto;
  position: relative;
  background-image: url('../assets/micardTournament/girl_full_body.png');
  background-size: 37% !important;
  background-repeat: no-repeat no-repeat;
  margin-top: 11%;
  background-position: right 0% top clamp(100px, -7%, 200px) !important;


  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 57%;
    background-size: 49% !important;
    background-position: center !important;
  }
}

.girl-mobile {
  width: 95%;
  height: 100%;
  margin: auto;
  position: relative;
  background-image: url('../assets/micardTournament/girl_hd.webp');
  background-size: contain !important;
  background-repeat: no-repeat no-repeat;
  margin-top: 58%;
  background-position: center !important;
}

.blur {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
  background: linear-gradient(to top, rgba(40,52,76,1) 30%, rgba(0,0,0,0) 40%);

  @media screen and (max-width: 768px) {
    top: -149px;
    background: linear-gradient(to top, rgb(40, 52, 76) 21%, rgba(0, 0, 0, 0) 40%);
  }
}

.blur-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
  background: linear-gradient(to top, rgba(40,52,76,1) 8%, rgba(0,0,0,0) 40%);

  @media screen and (max-width: 768px) {
    height: calc(100vw * 919/1440);
    background: linear-gradient(to top, rgb(40, 52, 76) 2%, rgba(0, 0, 0, 0) 13%);
  }
}

.blur-2-top {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
  background: linear-gradient(to bottom, rgba(40,52,76,1) 25%, rgba(0,0,0,0) 40%);

  @media screen and (max-width: 768px) {
    height: calc(100vw * 919/1440);
    background: linear-gradient(to bottom, rgb(40, 52, 76) 0%, rgba(0, 0, 0, 0) 14%);
  }
}

.tournament-logo {
  width: 100%;
  height: 100%;
  max-width: 1270px;
  margin: auto;
  background-size: 64% !important;
  background-repeat: no-repeat;
  background-position: left 26% top 42% !important;
  position: relative;

  @media screen and (max-width: 768px) {
    background-size: 88% !important;
    background-position: left 26% top 22% !important;
  }
}

.spin-for-fortune-title {
  height: 70px;
  font-size: 44px;
  font-weight: 700;

  @media screen and (max-width: 768px) {
    height: unset;
    font-size: 29px;
    line-height: 30px !important;
    padding: 0px 8px;
    margin-bottom: -10px !important;
  }
}

.top-title-text-logo {
  position: absolute;
  top: 7%;
  left: 21%;

  @media screen and (max-width: 768px) {
    left: 18%;
  }
}

.slanted-text {
  transform: rotate(-5deg)
             skew(-5deg);
  font-weight: 700;
  font-size: 62px;

  @media screen and (max-width: 768px) {
    height: unset;
    font-size: 28px;
  }

}

.prize-text-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 0px;
  white-space: pre-line;

  p {
    margin-bottom: unset;
  }
}

.prize-text {
  font-size: 36px;
  font-weight: 700;
  color: #FFD285;

  @media screen and (max-width: 768px) {
    height: unset;
    font-size: 18px;
  }

}

@-webkit-keyframes rotate-in-center {
  100% {
    -webkit-transform: rotate(-720deg);
            transform: rotate(-720deg);
    opacity: 1;
  }
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 0;
  }
}
@keyframes rotate-in-center {
  100% {
    -webkit-transform: rotate(-720deg);
            transform: rotate(-720deg);
    opacity: 1;
  }
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 0;
  }
}
