@use 'colors' as colors;
@use 'variables' as variables;

.faq-container {
  min-height: 570px;

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;

    aside {
      display: flex;
      align-items: center;
      margin-top: 36px;
    }
  }

  h2 {
    padding: 0px;
  }
}

.faq-content {
  width: 70%;
  margin: auto;
  white-space: pre-line;
  letter-spacing: 1px;

  .faq-section-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    border-bottom: 1px solid colors.$navbar-text-color;

    button {
      border: none;
      background: transparent;
      color: white;
      cursor: pointer;
      position: relative;
      width: 250px;
      height: 63px;
      padding-bottom: 13px;
      z-index: 2;

      strong {
        font-weight: 200;
        color: white;
        text-align: center;
      }

      
    }

  .btn-highlight {
    background: url('../assets/details/web_mt_btn_faq_on.webp');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

    .disabled-btn {
      strong {
        color: colors.$navbar-text-color;

      }
    }
  }

  strong {
    font-size: 24px;
  }

  .label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid colors.$navbar-text-color;
    padding: 0 24px;

    aside {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    strong {
      font-size: 20px;
      color: white;
      text-align: left;
    }
    
  }

  .custom-table {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 60%;
    min-width: 400px;
    max-width: 500px;
    margin: auto;
    padding: 0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0);
    .table-first-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 16px;
      border-radius: 8px 8px 0px 0px;
      background: variables.$table-first-row-background;
      color: white;
    }

    .table-other-rows {
      background: variables.$table-row-background;
      border-radius: 0px 0px 8px 8px;
    }
    .table-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-top: 1px solid #4b5d85;
      padding: 0 16px;
    }

    .table-row:last-child {
      border-radius: 0px 0px 8px 8px;
    }

    p {
      width: 220px;
      line-height: 1em;
      padding-left: 20px;
    }
    
  }

  .registration-container, .tournament-setting-container, .others-container {
    button {
      border: none;
      background: transparent;
      color: white;
      cursor: pointer;
      width: 100%;
      padding: 0px;
    }

    li {
      list-style: disc;
      line-height: 1.7;
    }
  }
  
  .no-border {
    border-bottom: none;
  }

  .back-shadow {
    background: rgba(0,0,0,0.3);
    border-bottom: 1px solid colors.$navbar-text-color;
    padding-bottom: 16px;
  }

  section {
    padding:  0px 50px 0px 30px;
    
    p {
      font-size: 18px;
      line-height: 1.7em;
      padding-left: 15px;
    }
  }

  table, tr, th, td {
    border: 1px solid white;
    padding: 8px 16px;
  }

  table {
    margin-left: 3.5em;
    border-collapse: collapse;
  }
  
  .images-container-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    img {
      border-radius: 8px;
    }
  }

  .q3-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    img {
      border-radius: 8px;
    }
  }

  .images-container-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    img {
      border-radius: 8px;
    }
  }
}

@media only screen and (max-width: 1117px) {
  .faq-content {
    .q3-image-container, .q2-image-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 32px;
    }
  }
}
@media only screen and (max-width: 872px) {

  .faq-container {
    min-height: 0px;
  }

  .faq-content {
    width: 90%;
    margin: auto;

    section {
      padding: 0px 16px 0px 10px;
      
      p {
        font-size: 16px;
      }
    }

    .faq-section-nav-container {
      justify-content: space-evenly;
      padding: 0px;
      button {
        border: none;
        background: transparent;
        color: white;
        cursor: pointer;
        position: relative;
        width: 150px;
        height: 50px;
        z-index: 2;
      }

      .btn-highlight {
        background: url('../assets/details/web_mt_btn_faq_on.webp');
        background-repeat: no-repeat;
        background-size: 150px 50px;
        background-position: center;
      }
    }

    .custom-table {
      min-width: unset;
      width: 100%;
    }
    strong {
      font-size: 20px;
      text-align: center;
    }
    .label-container {
      padding: 4px 24px;
      strong {
        font-size: 20px;
        padding-left: 16px;
      }
    }
    
    
    .images-container-row {
      flex-direction: column;
      gap: 32px;
    }

    .images-container-column {
      img {
        width: 100%;
        height: auto;
      }

    }

  }
  
}