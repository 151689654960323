@use 'colors' as colors;

.introduction-container {

  white-space: pre-line;

  .intro-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  .game-process-title {
    width: 50%;
    margin: 110px auto 70px auto;
    text-align: center;

    h3 {
      font-size: 1.5em;
      color: white;
    }
  }

  .step-skeleton-container {
    width: 50%;
    margin: 64px auto 0px auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;

    .skeleton-img-container {
      position: relative;
      margin-left: 177px;

      .skeleton-step-on-img-1 {
        position: absolute;
        top: 20px;
        left: 20px;
        
      }

      .skeleton-step-on-img-2 {
        position: absolute;
        top: 240px;
        left: 20px;
        
      }

      .skeleton-step-on-img-3 {
        position: absolute;
        top: 460px;
        left: 20px;
      }

      .skeleton-step-on-img-1, .skeleton-step-on-img-2, .skeleton-step-on-img-3 {
          // transition: all 1s ease;
          animation: fadeIn ease 1s;
      }

    }

    .steps-instructions {
      margin: -16px 16px -40px 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 400px;

      p {
        text-align: left;
        white-space: pre-line;
        width: 400px;
      }


    }
  }

  .step-skeleton-header {
    font-size: 24px; 
    color: colors.$introduction-card-text-color;
    font-weight: bold;

  }

  .step-skeleton-text {
    color: colors.$introduction-card-text-color;
    font-size: 18px;
    font-weight: 400;
  }

  p {
    text-align: center;
    width: 60%;
    margin: auto;
    line-height: 1.7em;
    letter-spacing: 1px;
    text-justify: auto;
  }

  .video-container {
    width: 60%;
    margin: auto;
    border-radius: 8px;
    margin-top: 64px;
    position: relative;


    video {
      width: 100%;
      aspect-ratio: 16/9;
      border-radius: 8px;
    }

    #mt-date {
      position: absolute;
      display: none;
      top: 75%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, 0);
      align-self: center;
      white-space: break-spaces;
      z-index: 1;
      color: white;
      text-shadow: 0 0 5px #fff;
    }

    .fade-in-text {
      animation: fadeIn 2s ease-in;
    }
  }

  .how-to-enter-container {
    width: 80%;
    margin: auto;
    border-radius: 8px;
    margin-top: 32px;
    background-image: linear-gradient(to right, #9f714b, #fed285, #9f714b);
    padding: 2px;

    .how-to-take-part {
      background-color: #28344c;
      padding-top: 32px;
      border-radius: 8px;
      height: 100%;
      width: 100%;

      p {
        width: unset;
        margin: 0px;
      }

      .divider-container {
        width: 50%;
        margin: auto;

        img {
          width: 100%;
          height: auto;
          margin: auto;
        }
      }

      .steps-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 64px;
        align-items: center;

        @media  screen and (min-width: 768px) {
          flex-direction: row;
          align-items: baseline;
          padding-bottom: 32px;
        }

        p {
          width: unset;
        }

        div {
          width: 25%;
          padding: 0px 16px;

          aside {
            color: #FFD285;
            ;
          }
          @media  screen and (max-width: 768px){
            width: 100%;
            padding: 1em;
            margin-top: 2em;
          }


        }

        .img-container {
          width: 100%;
          height: 150px;
          margin: 64px auto;
          display: flex;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            margin: auto;
          }

          .step-4-btn {
            max-height: 50px;
          }
        }

        aside {
          display: flex;
          align-items: center;
          gap: 16px;
          border-bottom: 1px solid #FFD285;
          padding-bottom: 16px;
          justify-content: center;

          strong {
            margin-bottom: 40px;
            font-style: italic;
          }
        }
      }
    }
  }
  .cards-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-x: clip;
  }

  .card-1, .card-2 {
    position: relative;
    background: transparent;
    width: 400px;
    height: 500px;
    transition: all 1s ease;
  }

  .desktop-card-1 {
    left: -1000px;
  }

  .desktop-card-2 {
    right: -1000px;
  }

  .moved1 {
    left: 0px;
  }

  .moved2 {
    right: 0px;
  }

  .card-1-content, .card-2-content {
    margin-top: 60px;
    p, strong {
      color: colors.$introduction-card-text-color;
      padding-bottom: 8px;
    }

    .card-divider {
      width: 50%;
      margin: auto;
      border-bottom: 1px solid colors.$introduction-card-text-color;
    }

    .ag-logo, .tournament-logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .ag-logo {
      margin-top: 93px;
    }

    .tournament-logo {
      margin-top: 16px;
    }

    .step-instructions {
      color: colors.$introduction-card-text-color;
      text-align: center;
    }

    
  }


  .how-to-take-part {
    
    h3 {
      font-size: 1.5em;
      text-align: center;
      margin: 64px 0px 28px 0px;
      color: white;
    }
  }
}

.card-header {
  font-size: 6em;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1;}
}

@media only screen and (max-width: 872px) {
  .introduction-container {

    > p {
      width: 90%;
      text-align: center;
      text-justify: inter-word;
      line-height: 2em;
    }
    .step-skeleton-container {
      width: 90%;
      margin: auto;
    }

    .step-skeleton-text {
      width: 80%;
    }

    .cards-container {
      display: flex;
      flex-direction: column;
      gap: 64px;
      align-items: center;
      justify-content: center;
    }

    .card-1, .card-2 {
      width: 100%;
      aspect-ratio: 4/5;
      margin: auto;
    }

    .card-2 {
      margin-bottom: 50px;
    }

    .video-container {
      width: 90%;
      margin: 40px auto;

      #mt-date {
        top: 70%;
        font-size: 16px;
      }
    }

    .mobile-steps-container {
      display: flex;
      flex-direction: column;
      gap: 64px;
      .mobile-first-step {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .fade-in {
          animation: fadeIn 1s ease;
        }
      }
      .mobile-step-on-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .mobile-step-on {
          position: absolute;
          top: 33px;

          .fade-in {
            animation: fadeIn 1s ease;
          }
        }
        
      }
    }
  }
}