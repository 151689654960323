@use 'colors' as colors;
@use 'variables' as variables;


.details-container {
  margin-top: 64px;
  
  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;

    aside {
      display: flex;
      align-items: center;
      margin-top: 36px;
    }
  }

  h2 {
    padding: 0px;
  }
}
.details-content {
  width: 70%;
  margin: auto;
  white-space: pre-line;
  margin-bottom: 128px;
  letter-spacing: 1px;

  .tournament-rules-container {
    button {
      border: none;
      background-color: transparent;
      color: white;
      width: 100%;
      cursor: pointer;
      padding: 0;
    }
  }

  .terms-conditions-container, .tr-section-container {
    padding: 4px 50px 0px 30px;
  }



  .details-section-nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    button {
      border: none;
      background: transparent;
      color: white;
      cursor: pointer;
      position: relative;
      flex: 1;
      height: 63px;
      padding: 8px;
      z-index: 2;
      strong {
        text-align: center;
      }

      
    }
    .btn-highlight {
      color: #FFD285;
      background-color: #102434;
    }
    .disabled-btn {
      color: #8B5830;
      background: url('../assets/newTournament/gold_tab.webp');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  
    strong {
      font-size: 20px;
    }
    @media screen and (max-width: 768px) {
      strong {
        font-size: 15px;
        
    }
    
  }

  .label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid colors.$navbar-text-color;
    padding: 0 24px;

    strong {
      font-size: 20px;
    }
    
  }

  .custom-table {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: 65%;
    min-width: 400px;
    margin: auto;
    padding-left: 16px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0);
    .table-first-row {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 0 16px;
      border-radius: 8px 8px 0px 0px;
      background: variables.$table-first-row-background;
      color: white;
    }

    .table-other-rows {
      background: variables.$table-row-background;
      border-radius: 0px 0px 8px 8px;
    }

    .table-row {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      border-top: 1px solid #4b5d85;
      padding: 0 16px;
    }

    .table-row:last-child {
      border-radius: 0px 0px 8px 8px;
    }

    p {
      width: 120px;
      font-size: 18px;
      line-height: 1em;
    }
    
  }
  li {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 1.7em;
    padding-left: 8px;
    list-style-type: disc;
  }

  .example-container {
    background-color: #00396C;
    padding: 16px 16px 16px 32px;
    border-radius: 8px;
  }

  .prizes-table {
    width: 80%;
    margin: 16px auto 32px auto;
    border: 3px solid white;
    border-collapse: collapse;

    tr {
      border: 3px solid white;
    }

    td {
      border: 3px solid white;
      padding: 8px;

      @media only screen and (max-width: 768px) {
        padding: 0px;
      }
    }

    .prize-data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 4px 16px;

      p {
        line-height: unset;
        margin: 0px;
        color: #FFD285;
      }

      aside {
        display: flex;
        gap: 8px;

        p:first-child {
          font-size: 16px;
          margin-top: 4px;
        }

        p {
          color: white;
        }
      }
    }
  }

  ul li {
    list-style-type: disc;
  }

  .no-border {
    border-bottom: none;
  }

  .back-shadow {
    background: rgba(0,0,0,0.3);
    border-bottom: 1px solid colors.$navbar-text-color;
  }

}

@media only screen and (max-width: 872px) {
  .details-content {
    width: 90%;
    margin: auto;
    margin-bottom: 64px;

    .terms-conditions-container, .tr-section-container {
      padding: 8px 16px 0px 8px;
    }
    
    .custom-table {
      width: 100%;
      padding-left: 16px;
      min-width: unset;

      p {
        width: 100px;
        font-size: 18px;
      }
    }

    .label-container {

      strong {
        font-size: 20px;
      }

    }

    li {
      font-size: 16px;
    }

}
  
}



